<template>
    <div class="c-partner--logos">
        <div class="c-partner--logos-title">
            <h6>Powered by</h6>
        </div>

        <div class="c-partner--logos-menu">
            <div 
                v-for="logo in partnerLogos" 
                :key="logo.id" 
                class="c-partner--logos-entry"
            >
                <a :href="logo.ext" target="_blank">
                    <img :src="images[`${logo.filename}`]" :alt="logo.title" class="c-partner--logo" />
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { filename } from 'pathe/utils'

    const glob = import.meta.glob('@/assets/images/logos/*.svg', {eager: true})
    const images = Object.fromEntries(
        Object.entries(glob).map(([key, value]) => [filename(key), value.default])
    )

    const partnerLogos = ref([
        {
            title: "W-S",
            ext: "https://walbert-schmitz.de",
            filename: "BSG_Walbert Schmitz_Logo-01",
            image: "_nuxt/assets/images/logos/BSG_Walbert Schmitz_Logo-01.svg",
        },
        {
            title: "Denkee",
            ext: "https://www.linkedin.com/in/christian-denk-denkee",
            filename: "BSG_DENKEE_Logo-01",
            image: "_nuxt/assets/images/logos/BSG_DENKEE_Logo-01.svg"
        },
        {
            title: "Limiter",
            ext: "",
            filename: "Trennstrich-01",
            image: "_nuxt/assets/images/logos/Trennstrich-01.svg"
        },
        {
            title: "RocketBeansTV",
            ext: "https://rocketbeans.tv/",
            filename: "BSG_RocketBeans_Logo-01",
            image: "_nuxt/assets/images/logos/BSG_RocketBeans_Logo-01.svg"
        },
        {
            title: "BraveNewWorlds",
            ext: "https://www.bravenewnerds.com/",
            filename: "BSG_BNN_Logo-01",
            image: "_nuxt/assets/images/logos/BSG_BNN_Logo-01.svg"
        },
        {
            title: "Instinct3",
            ext: "https://instinct3.de/",
            filename: "Instinct3",
            image: "_nuxt/assets/images/logos/Instinct3.svg"
        },
        {
            title: "TakeTV",
            ext: "https://taketv.net/",
            filename: "TakeTV",
            image: "_nuxt/assets/images/logos/TakeTV.svg"
        },
        {
            title: "QDrei",
            ext: "https://www.qdrei-events.de/",
            filename: "BSG_MediaMotor_Logo-01",
            image: "_nuxt/assets/images/logos/BSG_MediaMotor_Logo-01.svg"
        },
        {
            title: "Viralwunder",
            ext: "https://viralwunder.de/",
            filename: "Viralwunder-01",
            image: "_nuxt/assets/images/logos/Viralwunder-01.svg"
        },
        {
            title: "Mediamotor",
            ext: "https://media-motor.de/",
            filename: "MDMTR_logo",
            image: "_nuxt/assets/images/logos/MDMTR_logo.svg"
        },
        {
            title: "PlanetLan",
            ext: "https://planetlan.de/",
            filename: "BSG_PlanetLan_Logo-01",
            image: "_nuxt/assets/images/logos/BSG_PlanetLan_Logo-01.svg"
        }
    ])
</script>